.article-header-yellow{
  background: linear-gradient(0deg, white 0%, #fcc041 100%);
  background: -moz-linear-gradient(90deg, white 0%, #fcc041 100%);
  border-bottom: 2px solid #fcc041;
  border-top: 1px solid #fcc041;
  padding: 10px 8px;
  font-weight: bold;
  color: red;
}

.article-header-blue{
  background: linear-gradient(0deg, white 0%, #1E90FF 100%);
  background: -moz-linear-gradient(90deg, white 0%, #1E90FF 100%);
  border-bottom: 2px solid #1E90FF;
  border-top: 1px solid #1E90FF;
  padding: 10px 8px;
  font-weight: bold;
  color: red;
  color: #191970;
}

.article-header-green {
  background: linear-gradient(0deg, white 0%, #32CD32 100%);
  background: -moz-linear-gradient(90deg, white 0%, #32CD32 100%);
  border-bottom: 2px solid #32CD32;
  border-top: 1px solid #32CD32;
  padding: 10px 8px;
  font-weight: bold;
  color: red;
  color: #191970;
}
.article-header-darkviolet {
  background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#6666ff),color-stop(100%,#6666ff));
  background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#6666ff),color-stop(100%,#6666ff));
  background: -webkit-linear-gradient(90deg,#6666ff 0%,#6666ff 100%);
  background: -o-linear-gradient(90deg,#6666ff 0%, #6666ff 100%);
  background: -ms-linear-gradient(90deg,#6666ff 0%,#6666ff 100%);
  background: linear-gradient(0deg, white 0%, #6666ff 100%);
  background: -moz-linear-gradient(90deg, white 0%, #6666ff 100%);

  border-bottom: 2px solid #66f;
  border-top: 1px solid #66f;
  padding: 10px 8px;
  font-weight: bold;
  color: black;
}
.article-header-violet  {
  background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,rgba(255,255,255,1)),color-stop(100%,rgba(255,248,243,1)));
  background: -webkit-linear-gradient(90deg,rgba(255,255,255,1) 0%,#B06EFD 100%);
  background: -o-linear-gradient(90deg,rgba(255,255,255,1) 0%, #B06EFD 100%);
  background: -ms-linear-gradient(90deg,rgba(255,255,255,1) 0%,#B06EFD 100%);
  background: linear-gradient(0deg, white 0%, #B06EFD 100%);
  background: -moz-linear-gradient(90deg, white 0%, #B06EFD 100%);

  border-bottom: 2px solid #8F00FF;
  border-top: 1px solid #8F00FF;
  padding: 10px 8px;
  font-weight: bold;
  color: darkslategray;
}

.article-header-red  {
  background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,rgba(255,255,255,1)),color-stop(100%,rgba(255,248,243,1)));
  background: -webkit-linear-gradient(90deg,rgba(255,255,255,1) 0%,#B06EFD 100%);
  background: -o-linear-gradient(90deg,rgba(255,255,255,1) 0%, #B06EFD 100%);
  background: -ms-linear-gradient(90deg,rgba(255,255,255,1) 0%,#B06EFD 100%);
  background: linear-gradient(0deg, white 0%, #B06EFD 100%);
  background: -moz-linear-gradient(90deg, white 0%, #B06EFD 100%);

  border-bottom: 2px solid #8F00FF;
  border-top: 1px solid #8F00FF;
  padding: 10px 8px;
  font-weight: bold;
  color: darkslategray;
}

.article-header-purple  {
  background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#FF1493),color-stop(100%,#FF1493));
  background: -webkit-linear-gradient(90deg,#FF1493 0%,#FF1493 100%);
  background: -o-linear-gradient(90deg,#FF1493 0%, #FF1493 100%);
  background: -ms-linear-gradient(90deg,#FF1493 0%,#FF1493 100%);
  background: linear-gradient(0deg, white 0%, #FF1493 100%);
  background: -moz-linear-gradient(90deg, white 0%, #FF1493 100%);

  border-bottom: 2px solid #FF1493;
  border-right: 2px solid #FF1493;
  border-top: 1px solid #FF1493;
  padding: 10px 8px;
  font-weight: bold;
  color: darkslategray;
}

.article-header-brown  {
  background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#A52A2A),color-stop(100%,#A52A2A));
  background: -webkit-linear-gradient(90deg,#A52A2A 0%,#A52A2A 100%);
  background: -o-linear-gradient(90deg,#A52A2A 0%, #A52A2A 100%);
  background: -ms-linear-gradient(90deg,#A52A2A 0%,#A52A2A 100%);
  background: linear-gradient(0deg, white 0%, #A52A2A 100%);
  background: -moz-linear-gradient(90deg, white 0%, #A52A2A 100%);

  border-bottom: 2px solid #A52A2A;
  border-right: 2px solid #A52A2A;
  border-top: 1px solid #A52A2A;
  padding: 10px 8px;
  font-weight: bold;
  color: darkslategray;
}

.article-header-black  {
  background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#000000),color-stop(100%,#000000));
  background: -webkit-linear-gradient(90deg,#000000 0%,#000000 100%);
  background: -o-linear-gradient(90deg,#000000 0%, #000000 100%);
  background: -ms-linear-gradient(90deg,#000000 0%,#000000 100%);
  background: linear-gradient(0deg, white 0%, #000000 100%);
  background: -moz-linear-gradient(90deg, white 0%, #000000 100%);

  border-bottom: 2px solid #000000;
  border-right: 2px solid #000000;
  border-top: 1px solid #000000;
  padding: 10px 8px;
  font-weight: bold;
  color: white;
}

.article-header-lemon {
  background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#d1f007),color-stop(100%,#d1f007));
  background: -webkit-linear-gradient(90deg,#d1f007 0%,#d1f007 100%);
  background: -o-linear-gradient(90deg,#d1f007 0%, #d1f007 100%);
  background: -ms-linear-gradient(90deg,#d1f007 0%,#d1f007 100%);
  background: linear-gradient(0deg, white 0%, #d1f007 100%);
  background: -moz-linear-gradient(90deg, white 0%, #d1f007 100%);

  border-bottom: 2px solid #d1f007;
  border-right: 2px solid #d1f007;
  border-top: 1px solid #d1f007;
  padding: 10px 8px;
  font-weight: bold;
  color: black;
}

.article-header-red {
  background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#f20a11),color-stop(100%,#f20a11));
  background: -webkit-linear-gradient(90deg,#f20a11 0%,#f20a11 100%);
  background: -o-linear-gradient(90deg,#f20a11 0%, #f20a11 100%);
  background: -ms-linear-gradient(90deg,#f20a11 0%,#f20a11 100%);
  background: linear-gradient(0deg, white 0%, #f20a11 100%);
  background: -moz-linear-gradient(90deg, white 0%, #f20a11 100%);

  border-bottom: 2px solid #f20a11;
  border-right: 2px solid #f20a11;
  border-top: 1px solid #f20a11;
  padding: 10px 8px;
  font-weight: bold;
  color: black;;
}

.page-min{
  min-height: 29.7cm;
}
.page-scroll{
  min-height: 29.7cm;
  max-height: 29.7cm;
  overflow-y: scroll;
}
.page-fixed{
  min-height: 29.7cm;
  max-height: 29.7cm;
}
